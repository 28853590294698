







































import { Component, Vue, Watch, Mixins } from 'vue-property-decorator'
import StudentHistorySidebar from '@/components/organisms/StudentHistorySidebar.vue'
import TestTeacherHistoryHeader from '@/components/organisms/TestTeacherHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SubjectProgress from '@/components/organisms/SubjectProgress.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { ResultProgressesResponseType } from '@/models/api/resultProgresses'
import { SubjectProgressType, StepProgressMethods } from '@/mixins/utils/StepProgressMethods'
import TeacherHistoryBottomPanel from '@/components/organisms/TeacherHistoryBottomPanel.vue'

@Component({
  components: {
    StudentHistorySidebar,
    TestTeacherHistoryHeader,
    ColoredBox,
    TitleBase,
    TitleTextBase,
    ButtonBase,
    SubjectProgress,
    SelectBase,
    TeacherHistoryBottomPanel,
  },
})
export default class StepSituation extends Mixins(StepProgressMethods) {
  private userId = Vue.prototype.$cookies.get('dataGdls').student
    ? Vue.prototype.$cookies.get('dataGdls').student.userId
    : 0

  private hasTerm = true

  private selectTestOptions: { text: string; value: number }[] = []
  private selectedTest = 0
  private subjectSteps: SubjectProgressType[] = []

  @Watch('selectedTest')
  onSelectedTestChange() {
    this.getResultProgresses()
  }

  private async getTermExamsTerms() {
    await Vue.prototype.$http.httpWithToken
      .get('/termExams/terms', { params: { userId: this.userId } })
      .then((res: any) => {
        if (res.data.length === 0) {
          this.hasTerm = false
          return
        }
        this.selectTestOptions = res.data.map((term: { gradeName: string; termName: string; id: number }) => {
          return { text: `${term.gradeName} ${term.termName}`, value: term.id }
        })
        const selectedId = res.data.find((term: { isSelected: boolean }) => {
          return term.isSelected === true
        })
        this.selectedTest = selectedId.id
      })
  }

  private async mounted() {
    await this.getTermExamsTerms()
  }

  private async getResultProgresses() {
    if (!this.selectedTest) return
    await Vue.prototype.$http.httpWithToken
      .get(`/history/resultProgresses/${this.userId}`, { params: { termExamId: this.selectedTest } })
      .then(({ data }: { data: ResultProgressesResponseType }) => {
        this.subjectSteps = this.parseStepProgresses(data)
      })
      .catch(() => {
        this.subjectSteps = []
      })
  }
}
